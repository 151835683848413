import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";
import Sheet from 'react-modal-sheet';
import { Link } from 'react-router-dom';
import { CartList, PaymentMethodComp, CartPaymentMethod, EmptyCart, Vochers } from '../components/common';
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useSelector, useDispatch } from 'react-redux';
import CartAction from '../redux/actions/CartAction';
import { GrClose } from "react-icons/gr";
import { Helmet } from 'react-helmet';
import MasterAction from '../redux/actions/MasterAction';
const Cart = () => {
    const dispatch = useDispatch()
    const { getCartList, checkcoupan, getcoupanlistdata } = CartAction();
    const { getDeliveryChargesdata } = MasterAction();
    // const { checkcoupan } = useOrders();
    const cartpagerediredct = useSelector(state => state.masterdata.cartpagerediredct)
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const Deliverydata = useSelector(state => state.masterdata.Deliverydata);
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const coupanmsg = useSelector(state => state.cartdetail.coupanmsg);
    const bottomsheet = useSelector(state => state.cartdetail.bottomsheet);
    const cartDetaildup = useSelector(state => state.cartdetail.cartDetaildup);
    const [selectedDelivery, setSelectedDelivery] = useState({ id: null, price: null });
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const [coupon_code_value, setcoupon_code_value] = useState("");
    useEffect(() => {
        dispatch(getCartList())
        dispatch(getcoupanlistdata())
        dispatch(getDeliveryChargesdata())
    }, [])
    useEffect(() => {
        dispatch({
            type: 'setHeadercategory',
            payload: false,
        });
    }, []);
    useEffect(() => {

    }, [])

    const coupanUpdated = () => {

        const input_data = {
            coupan_code: coupon_code_value
        }
        dispatch(checkcoupan(input_data))
    }

    const handlechange = (e) => {
        setcoupon_code_value(e.target.value)
    }
    const selectedshipping = (price) => {

    }
    return (
        <Container className='my-cart-container'>
            <Helmet>
                <title>{localStorage.getItem("title")} - Cart</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
            </Helmet>
            {
                cartDetaildup != undefined ? (
                    cartDetail.result.filter(ele => ele.cart_status == 1).length > 0 ?
                        <Row className='my-cart'>
                            <div className='my-cart-container'>
                                <div className='my-cart-heading primaryColorcolor'>My Cart <span>({cartDetail.result.filter(ele => ele.cart_status == 1).length} items)</span></div>
                            </div>
                            <Col lg={8} md={12}>
                                <CartList />

                            </Col>
                            <Col lg={4} md={12} >

                                {
                                    authStatus &&
                                    <Vochers />
                                    // <div className='coupon'>
                                    //     <div className='coupon_header'>Apply coupon</div>
                                    //     <div className="input-area" >
                                    //         <div className="input-box-div">
                                    //             <input type="text" class="coupon-code-input-vhr-not-apld" name="coupon_code" onChange={(e) => handlechange(e)} id="couponCodeInput" placeholder="Enter coupon code" />
                                    //             <button className="rilrtl-button button apply-button primaryColor  " onClick={coupanUpdated}>Apply</button>
                                    //         </div>
                                    //         {/* <div className='coupanmsg'>{coupanmsg.message}</div> */}
                                    //         <div className='coupanmsg'>{coupanmsg.message}</div>
                                    //     </div>
                                    // </div>
                                }
                                {/* <div>
                                    <h5 className='detail_Specifications'>
                                        Delivery
                                    </h5>
                                    <Row>
                                        {Deliverydata.map((ele) => {
                                            const isSelected = selectedDelivery.id === ele.id; // Check if this delivery option is selected
                                            return (
                                                <Col lg={12} key={ele.id}>
                                                    <div
                                                        className={`detaildelevery ${isSelected ? 'selected-delivery' : ''}`}
                                                        onClick={() => setSelectedDelivery({ id: ele.id, price: ele.delivery_charges })} // Set selected delivery and price
                                                        style={{
                                                            border: isSelected ? '2px solid red' : '1px solid #ccc', // Apply conditional border
                                                            borderRadius: '5px', // Optional: Make border corners rounded
                                                            padding: '10px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <div className="detailpage_deliveryleft">
                                                            <div className="delivery_title">{ele.deliveryzone}</div>
                                                            <div className="delivery_days">{ele.leadtime}</div>
                                                        </div>
                                                        <div className="deliverystatus">
                                                            {ele.delivery_charges > 0 ? `${ele.delivery_charges} ${localStorage.getItem("currency")}` : "FREE"}
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                    {selectedDelivery.id !== null && (
                                        <div className="selected-delivery-price">
                                            Selected Delivery Price: <strong>{selectedDelivery.price > 0 ? `${selectedDelivery.price} ${localStorage.getItem("currency")}` : "FREE"}</strong>
                                        </div>
                                    )}
                                </div> */}
{
    !isDesktoporMobile &&
    <div className='payment_method mt-3 mb-3'>
    <PaymentMethodComp screentype={"cart"} deliverycharge={selectedDelivery.price} />
</div>
}
                             

                                {
                                    !isDesktoporMobile ?

                                        authStatus ?
                                            <Link to="/checkout" className=' text-decoration-none'>
                                                <div className='my-cart-button primaryColor' >
                                                    Continue
                                                </div>
                                            </Link>
                                            :
                                            <Link to="/login?redirect_url=cart" className=' text-decoration-none'>
                                                <div className='my-cart-button primaryColor' >
                                                    Login / Register
                                                </div>
                                            </Link>
                                        :
                                        <Row>
                                            <div className='buttons-product-detaildiv'>
                                                <div className='product-detail-buttongroup'>
                                                    <div
                                                        className="cart-detail-payment"
                                                        onClick={() => dispatch({ type: "setbottomsheet", payload: !bottomsheet })}
                                                    >
                                                        {localStorage.getItem("currency") +
                                                            Math.ceil(cartDetail.total)}{" "}
                                                        {!bottomsheet ? (
                                                            <BiChevronDown size="20" color="#ff7e00" />
                                                        ) : (
                                                            <BiChevronUp size="20" color="#ff7e00" />
                                                        )}
                                                    </div>

                                                    {authStatus ?
                                                        <Link to="/checkout" className='product-detail-button primaryColor text-decoration-none'>
                                                            Continue
                                                        </Link>
                                                        :
                                                        <Link to="/login?redirect_url=checkout" className='product-detail-button primaryColor text-decoration-none'>
                                                            Login / Register
                                                        </Link>
                                                    }

                                                </div>
                                            </div>

                                            <Sheet isOpen={bottomsheet} onClose={() => dispatch({ type: 'setbottomsheet', payload: false })}
                                            >
                                                <Sheet.Container className='custom-cart-modalsheet cartbottomsheet'>
                                                  
                                                    <Sheet.Content>
                                                    <div  className='cartbottomsheetclose'><GrClose   onClick={() => dispatch({ type: "setbottomsheet", payload: !bottomsheet })}/></div>
                                                    <PaymentMethodComp screentype={"cart"} deliverycharge={selectedDelivery.price} />
                                                        {/* <CartPaymentMethod type={"dropdown"} /> */}
                                                        <div className={`product-detail-buttongroup${!bottomsheet ? 'cart-bottomsheet-active' : ''}`}>
                                                            <div className='cart-detail-payment' onClick={() => dispatch({ type: 'setbottomsheet', payload: !bottomsheet })}>
                                                                {localStorage.getItem("currency") + Math.round(cartDetail.total)} {!bottomsheet ? <BiChevronDown size="20" color="#ff7e00" /> : <BiChevronUp size="20" color="#ff7e00" />}
                                                            </div>
                                                            {authStatus ?
                                                                <Link to="/checkout"  className='product-detail-button primaryColor text-decoration-none'>
                                                                    
                                                                    Continue
                                                                </Link>
                                                                :
                                                                <Link to="/checkout"  className='product-detail-button primaryColor text-decoration-none'>
                                                                    Login / Register
                                                                </Link>
                                                            }
                                                        </div>
                                                      
                                                    </Sheet.Content>
                                                </Sheet.Container>
                                                <Sheet.Backdrop />
                                            </Sheet>
                                        </Row>
                                }
                            </Col>
                        </Row>
                        :
                        <>
                            {
                                !cartpagerediredct ?
                                    <EmptyCart /> : <div className='custom-height'></div>
                            }
                        </>

                )
                    :
                    <div className='custom-height'></div>
            }

        </Container>
    );
};

export default Cart;