import React, { useContext } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

const Apis = () => {
  var cookies = new Cookies();
  axios.defaults.baseURL = "https://admin.shopersbay.com/";
  const carouselapi = useSelector((state) => state.masterdata.carouselapi);
  const carouselapiString = JSON.stringify(carouselapi);

  // axios.defaults.baseURL = "https://demo.shopersbay.com/";
  axios.defaults.headers.common['authentication'] = localStorage.getItem("qwerty");
  // axios.defaults.headers.common['authentication'] = "dd11b5274ea22417f4b419f0c2e8e0c7"

  // const HomepageComponent = async (input_data) => {
  //   const response = await axios.get(`${window.location.origin}/json/home.json?id=${Math.floor(Math.random() * 100000000)}`, input_data);
  //   return response;
  // };

  // const categorylistdata = async (input_data) => {
  //   const response = await axios.get(`${window.location.origin}/json/category_list.json?id=${Math.floor(Math.random() * 100000000)}`, input_data);
  //   return response;
  // };

  // const globaljson = async (input_data) => {
  //   const response = await axios.get(`${window.location.origin}/json/global.json?id=${Math.floor(Math.random() * 100000000)}`, input_data);
  //   return response;
  // }

  const HomepageComponent = async (input_data) => {
    const baseUrl = localStorage.getItem("baseurl") ? localStorage.getItem("baseurl") : localStorage.getItem("base_url")
    const url = `${(baseUrl && baseUrl !== "") ? baseUrl : "/assets/"}/home.json?id=${Math.floor(Math.random() * 100000000)}`;
    const response = await axios.get(url, input_data);
    return response;
  };

  const categorylistdata = async (input_data) => {
    const baseUrl = localStorage.getItem("baseurl") ? localStorage.getItem("baseurl") : localStorage.getItem("base_url")
    const url = `${(baseUrl && baseUrl !== "") ? baseUrl : "../assets/"}/category_list.json?id=${Math.floor(Math.random() * 100000000)}`;
    const response = await axios.get(url, input_data);
    return response;
  };

  const globaljson = async (input_data) => {
    const baseUrl = localStorage.getItem("baseurl") ? localStorage.getItem("baseurl") : localStorage.getItem("base_url")
    const url = `${(baseUrl && baseUrl !== "") ? baseUrl : "../assets/"}/global.json?id=${Math.floor(Math.random() * 100000000)}`;
    const response = await axios.get(url, input_data);
    return response;
  };

  const saveRatingService = async (input_data) => {
    const response = await axios.post("asapi/saveRating", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const Carouselapidata = async (input_data, api) => {
    const response = await axios.post(api, input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const Carouselapidataonclick = async (input_data, api) => {
    const response = await axios.post(api, input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const fourproductcard = async (input_data, api) => {
    const response = await axios.post(api, input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const productloadmore = async (input_data, api) => {
    const response = await axios.post(api, input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const Multifullcarouseldata = async (input_data, api) => {
    const response = await axios.post(api, input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const getNavigationlist = async () => {
    const response = await axios.get("asapi/getNavigationList");
    return response;
  };
  const bestproducts = async (input_data) => {
    const response = await axios.post("asapi/getBestSellerProduct", input_data);
    return response;
  }
  const getNewArrivalProduct = async (input_data) => {
    const response = await axios.post("asapi/getNewArrivalProduct", input_data);
    return response;
  }
  const sendotpService = async (input_data) => {
    const response = await axios.post("asapi/checkCustomer", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const customerreview = async (input_data) => {
    const response = await axios.post("asapi/getCustomerReviews", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const verifyotpService = async (input_data) => {
    const response = await axios.post("asapi/varifyOtp", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const verifyotpServiceNew = async (input_data) => {
    const response = await axios.post("asapi/varifyOtp_new", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const cancelOrderService = async (input_data) => {
    const response = await axios.post("asapi/cancelOrder", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const returnOrderService = async (input_data) => {
    const response = await axios.post("asapi/returnOrder", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const getMasterDataService = async () => {
    const response = await axios.get("asapi/getMasterData", {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const categoryListService = async (input_data) => {
    const response = await axios.get("asapi/getCategoryList");
    return response;
  };
  const getOtpService = async (input_data) => {
    const response = await axios.post("asapi/authForgetPassword", input_data,
      {
        headers: { authorization: cookies.get("jwt_token") }
      });
    return response;
  };

  const saveNewsletterdat = async (input_data) => {
    const response = await axios.post("asapi/saveNewsLetter", input_data)
    return response
  };
  const saveContactFormdata = async (input_data) => {
    const response = await axios.post("asapi/saveContact", input_data);
    return response;
  }


  const addressListService = async () => {
    const response = await axios.get("asapi/getAddressList", {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const getSubscription = async () => {
    const response = await axios.get("asapi/getSubscription", {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const getStaticPageListdata = async () => {
    const response = await axios.get("asapi/getStaticPageList", {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const updateAddress = async (input_data) => {
    const response = await axios.post("asapi/updatedeliveryAddress", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const onselectdeliverycahrges = async (input_data) => {
    const response = await axios.post("asapi/deliveryCharge", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const add2cartService = async (input_data) => {
    const response = await axios.post("asapi/addToCart", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const cartListService = async (input_data) => {
    const response = await axios.post("asapi/getcartList", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const updateCartService = async (input_data) => {
    const response = await axios.post("asapi/updateCart", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const getSubcategorydataService = async (input_data) => {
    const response = await axios.post("asapi/getSubcategorydata", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const getFilterSearchResultApi = async (input_data) => {
    const response = await axios.post("asapi/getFilterSearchResultNew", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const SaveRecentlyViewedApi = async (input_data) => {
    const response = await axios.post("asapi/saveRecentView", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const getRelatedItemsApi = async (input_data) => {
    const response = await axios.post("asapi/getRelatedItems", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const getProductDetailService = async (input_data) => {
    const response = await axios.post("asapi/getProductsDetail", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const getBundleproductslist = async (input_data) => {
    const response = await axios.post("asapi/getBundleProduct", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  }

  const changeCartQuantityService = async (input_data) => {
    const response = await axios.post("asapi/updateQuantity", input_data);
    return response;
  };

  const SignUpService = async (input_data) => {
    const response = await axios.post("asapi/register", input_data);
    return response;
  };
  const updateCustomerProfile = async (input_data) => {
    const response = await axios.post("asapi/updateCustomerProfile", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const LoginService = async (input_data) => {
    const response = await axios.post("asapi/authlogin", input_data);
    return response;
  };
  const othersourcelogindata = async (input_data) => {
    const response = await axios.post("asapi/othersourcelogin", input_data);
    return response;
  };
  const conformpaymentservice = async (input_data) => {
    const response = await axios.post("asapi/confirmPayment", input_data,
      {
        headers: { authorization: cookies.get("jwt_token") }
      });

    return response;
  };
  const checkcoupanid = async (input_data) => {
    const response = await axios.post("asapi/getCoupan", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const getcoupanlist = async () => {
    const response = await axios.get("asapi/getCoupanList",
      // {
      //   headers: { authorization: cookies.get("jwt_token") }
      // }
    );
    return response;
  };
  const getDeliveryCharges = async () => {
    const response = await axios.get("asapi/getDeliveryCharges",
      // {
      //   headers: { authorization: cookies.get("jwt_token") }
      // }
    );
    return response;
  };

  const getUserProfile = async () => {
    const response = await axios.get("asapi/getCustomerProfile", {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const getCustomerOrder = async () => {
    const response = await axios.get("asapi/getCustomerOrderList", {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const saveAddress = async (input_data) => {
    const response = await axios.post("asapi/saveAddress", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const checkCustomerdata = async (input_data) => {
    const response = await axios.post("asapi/varifyMailandPhone", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const deleteAddressService = async (input_data) => {
    const response = await axios.post("asapi/deleteAddress", input_data);
    return response;
  };
  const getPaymentMethodService = async (input_data) => {
    const response = await axios.get("asapi/getPaymentMethod", {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const postplaceorderService = async (input_data) => {
    const response = await axios.post("asapi/placeOrder", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const varifyOtpService = async (input_data) => {
    const response = await axios.post("asapi/varifyOtp_new", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const updateCheckoutService = async (input_data) => {
    const response = await axios.post("asapi/checkout", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const getOrderDetailByIdService = async (slug1, slug2) => {
    if (slug2 != undefined) {
      var response = await axios.get(`asapi/getOrderDetailById/${slug1}/${slug2}`, {
        headers: { authorization: cookies.get("jwt_token") }
      });
    } else {
      var response = await axios.get(`asapi/getOrderDetailById/${slug1}`, {
        headers: { authorization: cookies.get("jwt_token") }
      });
    }

    return response;
  };

  const SearchProductService = async (input_data) => {
    const response = await axios.post("asapi/searchProduct", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const SearchResultService = async (input_data) => {
    const response = await axios.post("asapi/getSearchResult", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const deletecartService = async (input_data) => {
    const response = await axios.post("asapi/deleteCart", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const gvt = async () => {
    const response = await axios.post(`asapi/gvt`);
    // const response = await axios.get(`${window.location.origin}/master.json`);
    return response;
  };
  const saveSubscription = async (input_data) => {
    const response = await axios.post("asapi/saveSubscription", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const updatepwdService = async (input_data) => {
    const response = await axios.post("asapi/forgetPassword", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  const updateAuthPasswordservice = async (input_data) => {
    const response = await axios.post("asapi/updateAuthPassword", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const add2wishlistService = async (input_data) => {
    const response = await axios.post("asapi/addToWishList", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };
  return {
    getNavigationlist: getNavigationlist,
    saveSubscription: saveSubscription,
    globaljson: globaljson,
    cancelOrderService: cancelOrderService,
    returnOrderService: returnOrderService,
    getCustomerOrder: getCustomerOrder,
    HomepageComponent: HomepageComponent,
    getMasterDataService: getMasterDataService,
    categoryListService: categoryListService,
    addressListService: addressListService,
    add2cartService: add2cartService,
    cartListService: cartListService,
    getSubcategorydataService: getSubcategorydataService,
    getFilterSearchResultApi: getFilterSearchResultApi,
    SaveRecentlyViewedApi: SaveRecentlyViewedApi,
    getRelatedItemsApi: getRelatedItemsApi,
    conformpaymentservice: conformpaymentservice,
    getProductDetailService: getProductDetailService,
    changeCartQuantityService: changeCartQuantityService,
    SignUpService: SignUpService,
    LoginService: LoginService,
    checkcoupanid: checkcoupanid,
    updateAddress: updateAddress,
    saveContactFormdata: saveContactFormdata,
    getUserProfile: getUserProfile,
    saveNewsletterdat: saveNewsletterdat,
    saveAddress: saveAddress,
    Carouselapidata: Carouselapidata,
    Carouselapidataonclick: Carouselapidataonclick,
    getcoupanlist: getcoupanlist,
    getDeliveryCharges: getDeliveryCharges,
    productloadmore: productloadmore,
    fourproductcard: fourproductcard,
    deleteAddressService: deleteAddressService,
    getPaymentMethodService: getPaymentMethodService,
    postplaceorderService: postplaceorderService,
    updateCheckoutService: updateCheckoutService,
    getOrderDetailByIdService: getOrderDetailByIdService,
    updateCartService: updateCartService,
    SearchProductService: SearchProductService,
    SearchResultService: SearchResultService,
    deletecartService: deletecartService,
    gvt: gvt,
    onselectdeliverycahrges: onselectdeliverycahrges,
    othersourcelogindata: othersourcelogindata,
    updateAuthPasswordservice: updateAuthPasswordservice,
    getStaticPageListdata: getStaticPageListdata,
    getOtpService: getOtpService,
    getSubscription: getSubscription,
    checkCustomerdata: checkCustomerdata,
    getNewArrivalProduct: getNewArrivalProduct,
    bestproducts: bestproducts,
    varifyOtpService: varifyOtpService,
    updatepwdService: updatepwdService,
    updateCustomerProfile: updateCustomerProfile,
    saveRatingService: saveRatingService,
    add2wishlistService: add2wishlistService,
    customerreview: customerreview,
    sendotpService: sendotpService,
    getBundleproductslist: getBundleproductslist,
    categorylistdata: categorylistdata,
    verifyotpService: verifyotpService,
    verifyotpServiceNew: verifyotpServiceNew,
    Multifullcarouseldata: Multifullcarouseldata,
  }

}

export default Apis;
