import { DesktopLayout, Layout2 } from '../components/layout';
import * as ROUTES from '../constants/routes';
import React from 'react';
import { Route, BrowserRouter as Router, Routes, Redirect } from 'react-router-dom';
import * as view from '../views';
import { ScrollTop } from '../hooks';
import { Navigate } from 'react-router-dom';
const AppRouter = React.memo(() => {
  return (
    <Router >
      <Routes>
        {/* <Route path='*' element={<Navigate to='/' />} /> */}
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.OrderDetail />
              </DesktopLayout>
            </ScrollTop>
          }
          path={ROUTES.OrderDetail}
        />
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.BlogDetails />
              </DesktopLayout>
            </ScrollTop>
          }
          path={ROUTES.BlogDetails}
        />
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.Bestproduct />
              </DesktopLayout>
            </ScrollTop>
          }
          path={ROUTES.Deals}
        />
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.Homeseeall />
              </DesktopLayout>
            </ScrollTop>
          }
          path={ROUTES.seealldeals}
        />
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.NewArrival />
              </DesktopLayout>
            </ScrollTop>
          }
          path={ROUTES.NewArrival}
        />
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.BlogList />
              </DesktopLayout>
            </ScrollTop>
          }
          path={ROUTES.BlogList}
        />
        <Route
          element=
          {
            <ScrollTop>
              <view.SearchComp />
            </ScrollTop>
          }
          path={ROUTES.SearchComp}
        />
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.RatedProduct />
              </DesktopLayout>
            </ScrollTop>

          }
          path={ROUTES.RatedProduct}
        />
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.Pricing />
              </DesktopLayout>
            </ScrollTop>

          }
          path={ROUTES.Pricing}
        />
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.Democatpage />
              </DesktopLayout>
            </ScrollTop>

          }
          path={"/democart"}
        />
        <Route
          element=
          {
            <ScrollTop>
                <Layout2>
                <view.ForgotPassword />
                </Layout2>
            </ScrollTop>

          }
          path={ROUTES.ForgotPassword}
        />
        <Route
          element=
          {
            <ScrollTop>
              <Layout2>
                <view.BuisnessRegister />
              </Layout2>
            </ScrollTop>
          }

          path={ROUTES.BuisnessRegister}
        />
        <Route
          element={
            <DesktopLayout>
              <ScrollTop>
                <view.Policies />
              </ScrollTop>
            </DesktopLayout>
          }
          exact
          path={ROUTES.policies}
        />
        <Route
          element={
            // <DesktopLayout>
              <ScrollTop>
                <view.Apppage />
              </ScrollTop>
            // </DesktopLayout>
          }
          exact
          path={ROUTES.Apppage}
        />
        <Route
          element={
            <DesktopLayout>
              <ScrollTop>
                <view.MyOrder />
              </ScrollTop>
            </DesktopLayout>
          }
          exact
          path={ROUTES.MyOrder}
        />
        <Route
          element=
          {
            <DesktopLayout>
              <ScrollTop>
                <view.Home />
              </ScrollTop>
            </DesktopLayout>
          }

          path={ROUTES.HOME}
        />
        <Route
          element={
            <DesktopLayout>
              <ScrollTop>
                <view.ProductCat />
              </ScrollTop>
            </DesktopLayout>
          }
          exact
          path={ROUTES.productsCategory}
        />

        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.ProductDetail />
              </DesktopLayout>
            </ScrollTop>

          }
          exact
          path={ROUTES.ProductDetail}
        />
        <Route
          element=
          {

            <DesktopLayout>
              <ScrollTop>
                <view.Cart />
              </ScrollTop>
            </DesktopLayout>

          }

          path={ROUTES.CartDetail}
        />
        <Route
          element=
          {
            <Layout2>
            <view.Login />
             </Layout2>
          }
          path={ROUTES.Login}
        />
        <Route
          element=
          {
            <Layout2>
            <view.Register />
           </Layout2>

          }

          path={ROUTES.Register}
        />
        <Route
          element={
            <ScrollTop>
              <DesktopLayout>
                <view.AddressListComp />
              </DesktopLayout>
            </ScrollTop>
          }
          exact
          path={ROUTES.addresses}
        />
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.Checkout />
              </DesktopLayout>
            </ScrollTop>
          }
          path={ROUTES.checkout}
        />
        <Route
          element={
            <DesktopLayout>
              <ScrollTop>
                <view.OrderSuccess />
              </ScrollTop>
            </DesktopLayout>
          }
          exact
          path={ROUTES.OrderSuccessPage}
        />
        <Route
          element={
            <ScrollTop>
              <DesktopLayout>
                <view.SearchCat />
              </DesktopLayout>
            </ScrollTop>
          }
          exact
          path={ROUTES.searchResult}
        />
        <Route
          element={
            <DesktopLayout>
              <ScrollTop>
                <view.ProductSubCat />
              </ScrollTop>
            </DesktopLayout>
          }
          exact
          path={ROUTES.productsSubCategory}
        />
        <Route
          element={
            <ScrollTop>
              <view.Search />
            </ScrollTop>
          }
          exact
          path={ROUTES.Searchsuggestion}
        />
        <Route
          element={
            <ScrollTop>
              <DesktopLayout>
                <view.WishList />
              </DesktopLayout>
            </ScrollTop>
          }
          exact
          path={ROUTES.wishlist}
        />
        <Route
          element=
          {
            <ScrollTop>
              <DesktopLayout>
                <view.Myaccount />
              </DesktopLayout>
            </ScrollTop>
          }

          path={ROUTES.myaccount}
        />
        <Route
          element={
            <ScrollTop>
              <DesktopLayout>
                <view.MyaccountSubscription />
              </DesktopLayout>
            </ScrollTop>
          }
          exact
          path={ROUTES.myAccountSubscription}
        />
        <Route
          element={
            <ScrollTop>
              <DesktopLayout>
                <view.ProfileForm />
              </DesktopLayout>
            </ScrollTop>
          }
          exact
          path={ROUTES.profile}
        />
        <Route
          element={
            <ScrollTop>
              <DesktopLayout>
                <view.Subscription />
              </DesktopLayout>
            </ScrollTop>
          }
          exact
          path={ROUTES.subscription}
        />
      </Routes>

    </Router>

  )

});

export default AppRouter;
