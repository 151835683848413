import React, { useContext, useState } from 'react';
import { BsExclamationCircleFill, BsArrowRightCircleFill } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
const InputBox = ({ label, id, handleChange, disabled, change_btn, subcribre, errors, value, currentStep, classname, handleSubmit }) => {
    const dispatch = useDispatch()
    const openModal = useSelector(state => state.cartdetail.openModal);
    const [showpassword, setShowpassword] = useState(true)

    const updatedProfilephone = {}
    const setotpformType = ''

    const handleClick = (id) => {
        dispatch({
            type: 'setopenModal',
            payload: !openModal
        });
        // setotpformType(id)
    }
    return (
        <>
            {
                disabled ?
                    <>
                        <div className='field-holder'>
                            <input type="text" id={id} placeholder={label} name={id} defaultValue={updatedProfilephone.hasOwnProperty(id) ? updatedProfilephone[id] : value} className={`custom-input custom-input-disabled ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} />
                            {/* <label htmlFor={id} className={`custom-label ${subcribre}  primaryColorcolor`}>{label}</label> */}
                            {
                                change_btn == undefined &&
                                <div className="custom-change-icon " onClick={() => handleClick(id)}>
                                    <div className='email_phone_update secondaryColor'>
                                        <div className='editprofile secondaryColor'><MdModeEdit /></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className='field-holder ' style={{
                            pointerEvents: currentStep == 1 ? 'none' : '',
                            opacity: currentStep == 1 ? '.5' : '1',
                        }}>
                            {
                                id == 'pincode' || id == 'phone' ?

                                    <input type={'number'} id={id} placeholder={id === 'phone' ? label + '*' : label} name={id} value={value} className={`custom-input inputnumber ${classname} ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} required onChange={handleChange} /> :
                                    <input type={id == 'password' && showpassword ? 'password' : "text"} id={id} placeholder={label} name={id} value={value} className={`custom-input ${classname} ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} required onChange={handleChange} />
                            }

                            {/* <label htmlFor={id} className={`custom-label ${subcribre} primaryColorcolor`}>{label}</label> */}
                            {/* {errors.hasOwnProperty(id) && <BsExclamationCircleFill className="error-icon" />} */}
                            {classname == 'input-bg-black' && <BsArrowRightCircleFill className="subsribe-arrow-icon primaryColorcolor" onClick={handleSubmit} />}
                            {
                                id == 'password' &&
                                <div className='passwordeyeicon'> {showpassword ? <IoEyeOffOutline onClick={() => { setShowpassword(false) }} /> : <IoEyeOutline onClick={() => { setShowpassword(true) }} />}</div>
                            }
                        </div>
                        {errors.hasOwnProperty(id) && <span className='error-text'>{errors[id]}</span>}
                    </>
            }
        </>
    );
};

export default InputBox;