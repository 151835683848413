import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
const Policies = ()=>{
    const {slug1} =useParams();
    const footerdata = useSelector(state => state.masterdata.footerdata);
  
    const [data,setdata] = useState([]);
    useEffect(()=>{
    if(footerdata.length > 0){
      setdata(footerdata.filter(ele => ele.list_key == slug1))
    }
    },[slug1,footerdata])
    return(
      <Container>
   <div className="policies-main">
            {
              data.length > 0 ?
              <>
              <h1 className="policies-titles">{data[0].title}</h1>
              <div dangerouslySetInnerHTML={{ __html: data[0].body }} />
              </>:
               <div className='custom-height'></div>
            }
          
          </div>
      </Container>
       
    )
}
export default Policies;