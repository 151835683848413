import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Client, Account, OAuthProvider } from 'appwrite';
import { FaApple } from "react-icons/fa";
import AuthAction from '../../redux/actions/AuthAction';
import apple from '../../../src/images/apple.png';

// Appwrite Client Setup
const client = new Client()
  .setEndpoint('https://cloud.appwrite.io/v1') // Appwrite endpoint
  .setProject('677667b200045797ed19'); // Project ID

const account = new Account(client);

const Applesign = () => {
  const appleuser = useSelector((state) => state.authdetail.appleuser);
  const { othersourcelogindataapi } = AuthAction();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  // Function to handle the redirect flow
  const handleRedirect = async () => {
    try {
      // Fetch logged-in user details
      const loggedInUser = await account.get();

      // Dispatch user details to Redux
      dispatch({
        type: 'setappleuser',
        payload: loggedInUser,
      });

      // Prepare payload for API call
      const input_data = {
        source: 'apple',
        ...(localStorage.getItem('sessionId') && { session_id: localStorage.getItem('sessionId') }),
        source_uid: loggedInUser.$id,
        name: loggedInUser.name || loggedInUser.email,
        mailid: loggedInUser.email,
      };

      // Call API to handle user data
      const response = await dispatch(othersourcelogindataapi(input_data));

      // Save session ID if provided
      if (response?.sessionId) {
        localStorage.setItem('sessionId', response.sessionId);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  // Login function for Sign in with Apple (SIWA)
  const loginSIWA = async () => {
    try {
      // Mark redirect initiation
      localStorage.setItem('oauthRedirect', 'true');

      // Start OAuth session
      await account.createOAuth2Session(
        OAuthProvider.Apple,
        window.location.origin + window.location.pathname, // Success redirect URI
        window.location.origin + window.location.pathname // Failure redirect URI
      );
    } catch (error) {
    }
  };

  // Logout function
  const logout = async () => {
    try {
      // Delete current session
      await account.deleteSession('current');

      // Clear user details from Redux
      dispatch({
        type: 'setappleuser',
        payload: null,
      });
      dispatch({ type: 'LOGOUT' }); // Example Redux logout action

      // Remove session ID from localStorage
      localStorage.removeItem('sessionId');
    } catch (error) {
    }
  };

  // Effect to check for post-login redirect
  useEffect(() => {
    const checkRedirect = async () => {
      if (localStorage.getItem('oauthRedirect')) {
        localStorage.removeItem('oauthRedirect');
        await handleRedirect();
      } else {
        setIsLoading(false);
      }
    };

    checkRedirect();
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="linkwithggogle-apple">
        Loading...
        <img className="linkwithggogle-appleicon" src={apple} alt="Apple Icon" />
      </div>
    );
  }

  return (
    <div id="app">
      {appleuser ? (
        <div>
          <div className="linkwithggogle-apple" onClick={logout}>
          <FaApple size={20} />
            Logout with Apple
        
          </div>
        </div>
      ) : (
        <div>
          <div className="linkwithggogle-apple" onClick={loginSIWA}>
          <FaApple size={25} className='me-2' />
            Sign in with Apple
          </div>
        </div>
      )}
    </div>
  );
};

export default Applesign;
