import React, { useEffect, useState } from 'react';
import { Container, Badge, Row } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";
import { IoClose } from 'react-icons/io5';
import Sheet from 'react-modal-sheet';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const MobileFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [currentactivelen, setactivelen] = useState([]);

    const productcatData = useSelector(state => state.catlogdetail.productcatData);
    const filterChecked = useSelector(state => state.catlogdetail.filterChecked);
    const filtersbottomsheet = useSelector(state => state.catlogdetail.filtersbottomsheet);
    const selectedItem = useSelector(state => state.catlogdetail.selectedItem);
    const checkItems = useSelector(state => state.catlogdetail.checkItems);

    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    useEffect(() => {
        const queryObj = Object.fromEntries([...searchParams.entries()]);
        const finalInput = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value.split(",").map((ele) => ele.split("_")[0]),
        }));

        if (finalInput.length > 0) {
            setactivelen(finalInput);
        } else {
            setactivelen([]);
        }
    }, [slug, searchParams]);

    const handelCheckBox = (e, data) => {
        const updatedCheckItems = e.target.checked
            ? [...checkItems, e.target.value]
            : checkItems.filter(item => item !== e.target.value);

        dispatch({ type: 'setCheckItems', payload: updatedCheckItems });

        const ParamsValue = searchParams.get(data.title) || "";
        const paramsArray = ParamsValue.split(",").filter(Boolean);

        if (e.target.checked) {
            paramsArray.push(e.target.value);
        } else {
            const index = paramsArray.indexOf(e.target.value);
            if (index > -1) paramsArray.splice(index, 1);
        }

        if (paramsArray.length > 0) {
            searchParams.set(data.title, paramsArray.join(","));
        } else {
            searchParams.delete(data.title);
        }

        setSearchParams(searchParams);
        const updatedFilters = Object.fromEntries([...searchParams.entries()]);
        const finalInput = Object.entries(updatedFilters).map(([key, value]) => ({
            title: key,
            value: value.split(",").map(ele => ele.split("_")[0]),
        }));

        setactivelen(finalInput);
    };

    const clearFilterArray = () => {
        currentactivelen.forEach(ele => searchParams.delete(ele.title));
        setSearchParams(searchParams);
        setactivelen([]);
        dispatch({ type: 'setCheckItems', payload: [] });
        dispatch({ type: 'setfiltersbottomsheet', payload: false });
    };

    const handleApply = () => {
        const queryObj = {};
        currentactivelen.forEach(filter => {
            queryObj[filter.title] = filter.value.join(",");
        });

        Object.entries(queryObj).forEach(([key, value]) => {
            searchParams.set(key, value);
        });

        setSearchParams(searchParams);
        dispatch({ type: 'setfiltersbottomsheet', payload: false });
    };

    return (
        <Container fluid>
            {productcatData.hasOwnProperty("filters") && isDesktoporMobile && (
                <Sheet
                    isOpen={filtersbottomsheet}
                    onClose={() => dispatch({ type: 'setfiltersbottomsheet', payload: false })}
                >
                    <Sheet.Container className="custom-filter-modalsheet">
                        <Sheet.Content>
                            <div className="custom-filter-sheet-haeder">
                                <div className="filter_title">Filters</div>
                                <div
                                    className="icon"
                                    onClick={() => dispatch({ type: 'setfiltersbottomsheet', payload: false })}
                                >
                                    <IoClose size="20" />
                                </div>
                            </div>

                            <div className="filter-body">
                                <div className="filter-body-leftside">
                                    {productcatData.filters.checkbox.map((ele, index) => (
                                        <div
                                            key={index}
                                            className={`${filterChecked === index ? 'active-category ' : ''}fOzBOt`}
                                            onClick={() =>
                                                dispatch({ type: 'setfilterChecked', payload: index })
                                            }
                                        >
                                            {ele.title}{" "}
                                            <Badge bg="" className="custom-badge-filters">
                                                {
                                                    currentactivelen.filter(act => act.title === ele.title)
                                                        .length > 0 &&
                                                    currentactivelen.filter(act => act.title === ele.title)[0]
                                                        .value.length
                                                }
                                            </Badge>
                                        </div>
                                    ))}
                                </div>
                                <div className="filter-body-rightside">
                                    <div className="filterItems">
                                        <ul>
                                            {productcatData.filters.checkbox[filterChecked]?.list.map(item => (
                                                <li key={item.attr_value_id}>
                                                    <div className="list">
                                                        <input
                                                            type="checkbox"
                                                            value={item.attr_value_id}
                                                            checked={checkItems.includes(item.attr_value_id)}
                                                            onChange={e =>
                                                                handelCheckBox(
                                                                    e,
                                                                    productcatData.filters.checkbox[filterChecked]
                                                                )
                                                            }
                                                            id={`inline-checkbox-${item.attr_value_id}`}
                                                        />
                                                        <label htmlFor={`inline-checkbox-${item.attr_value_id}`}>
                                                            {item.attr_value_name}
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <Row>
                                <div className="buttons-product-detaildiv mobile-filter-apply-btn">
                                    <div className="product-detail-buttongroup">
                                        <div
                                            className="product-detail-button primaryColor product-detail-buttonactive"
                                            onClick={clearFilterArray}
                                        >
                                            Clear All
                                        </div>
                                        <div
                                            className="product-detail-button primaryColor"
                                            onClick={handleApply}
                                        >
                                            Apply
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop />
                </Sheet>
            )}
        </Container>
    );
};

export default MobileFilters;
