const initialState = {
  selectedColor:'',
  razzer:[],
  orderDetail:[],
  deliverdatetime:{},
  deliverslot:[],
  myorders:undefined,
  emailotp:"",
  opencheckoutstatus:"1",
  verification_status:undefined,
  selectdelivertaddress:""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'setVerification_status':
      return {
        ...state,
        verification_status: action.payload
      };
    case 'setselectdelivertaddress':
      return {
        ...state,
        selectdelivertaddress: action.payload
      };
    case 'setopencheckoutstatus':
      return {
        ...state,
        opencheckoutstatus: action.payload
      };
    case 'setemailotp':
      return {
        ...state,
        emailotp: action.payload
      };
    case 'setSelectedColor':
      return {
        ...state,
        selectedColor: action.payload
      };
      case 'setMyorder':
      return {
        ...state,
        myorders: action.payload
      };
    case 'setRazzer':
      return {
        ...state,
        razzer: action.payload
      };
    case 'setdeliverdatetime':
      return {
        ...state,
        deliverdatetime: action.payload
      };
    case 'setdeliverslot':
      return {
        ...state,
        deliverslot: action.payload
      };
    case 'setorderDetail':
      return {
        ...state,
        orderDetail: action.payload
      };
    default:
      return state;
  }
};
