export const HOME = '/';
export const ProductDetail = '/product/:slug';
export const BundellDetail = '/bundell-details/:slug';
export const CartDetail = '/cart';
export const ForgotPassword = '/password-reset';
export const Register = '/register';
export const Login = '/login';
export const Deals ='/deals/bestseller';
export const NewArrival ='/deals/NewArrival';
export const seealldeals ='/deals/:slug';
export const BuisnessRegister = '/buisness-register';
export const checkout = '/checkout';
export const myaccount = '/my-account';
export const productCat = '/product-cat';
export const searchResult = '/search-result/:slug';
export const productsSubCategory = '/subcategory/:slug';
export const productsCategory = '/category/:slug';
export const BundellProducts = '/bundell-products';
export const MyOrders = '/my-orders';
export const MyOrder = '/my-orders';
export const addresses = '/addresses';
export const SearchComp='/SearchComp';
export const BlogDetails='/BlogDetails';
export const BlogList='/BlogList';
export const wishlist = '/wishlist';
export const Searchsuggestion = '/searchsuggestion';
export const RatedProduct = '/RatedProduct/:slug';
export const profile = '/my-account/profile';
export const subscription = '/subscription';
export const OrderSuccessPage = '/order-success/:slug';
export const myAccountSubscription = '/my-account/subscription';
export const OrdeDetailsPage = '/order-details/:slug1/:slug2';
export const policies = '/policies/:slug1';
export const OrderDetail='/OrderDetail';
export const Pricing='/contact';
export const Apppage='/app';