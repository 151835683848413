import React, { useContext, useEffect, useState } from 'react';
import { BsXLg } from 'react-icons/bs';
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from 'react-redux';

const PaymentMethodComp = ({ screentype, paymentData, type, deliverycharge }) => {
    const dispatch = useDispatch()
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const bottomsheet = useSelector(state => state.cartdetail.bottomsheet);
    const discount = useSelector(state => state.cartdetail.discount);
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const [updateddiscount, setupdateddiscount] = useState(0);
    useEffect(() => {
        setupdateddiscount(parseFloat(cartDetail.total_mrp) - parseFloat(cartDetail.total))
    }, [cartDetail])
    return (
        <>
            {
                type == 'dropdown' ?
                    <div className='justify-space-between d-flex'>
                        <div className='payment_header'>Payment Details</div>
                        <BsXLg onClick={() => dispatch({ type: 'setbottomsheet', payload: !bottomsheet })} />
                    </div> :
                    <div className='payment_header'>Payment Details</div>
            }
            <div className='detail_row'>
                <div className='detail_row_left'>MRP Total</div>
                <div className='detail_row_right'>{screentype == 'order-detail' ? paymentData.selling_price : localStorage.getItem("currency") + cartDetail.total_mrp}</div>
            </div>
            {
                updateddiscount != 0 &&
                <div className='detail_row'>
                    <div className='detail_row_left'>Discount</div>
                    <div className='detail_row_right active-font-size-green'>- {screentype == 'order-detail' ? 0 : localStorage.getItem("currency") + (updateddiscount.toFixed(2))}</div>
                </div>
            }
            {
                discount > 0 &&
                <div className='detail_row'>
                    <div className='detail_row_left'>Voucher Discount</div>
                    <div className='detail_row_right active-font-size-green'>-{ (discount)}</div>
                </div>
            }
            <div className='detail_row'>
                <div className='detail_row_left'>Delivery Charges</div>
                <div className='detail_row_right active-font-size-green'>
                    {/* Exact: {cartDetail.total_shipping_cost + deliverycharge},  */}
                    {(cartDetail.total_shipping_cost + deliverycharge)}
                </div>

            </div>
            <div className='detail_row'>
                <div className='detail_row_left'>Total</div>
                <div className='detail_row_right active-font-size-black'>
                    {screentype === 'order-detail'
                        ? localStorage.getItem("currency") + paymentData.selling_price
                        : localStorage.getItem("currency") + (
                            ((parseFloat(cartDetail.total) + cartDetail.total_shipping_cost + (deliverycharge)) - discount)
                        )
                    }
                </div>

            </div>

        </>
    );
};

export default PaymentMethodComp;