export default function validate(values) {
    let errors = {};
    if (values.mailid === '') {
        errors.mailid = 'Email address is required';
    }
    if (values.email === '') {
        errors.email = 'Email address is required';
    }
    if (values.username === '') {
        errors.username = 'Email address is required';
    }
    if (values.address1 === '' || values.address1 === null) {
        errors.address1 = 'H.NO address is required';
    }
    if (values.city === '' || values.city === null) {
        errors.city = 'City is required';
    }
    if (values.address2 === '' || values.address2 === null) {
        errors.address2= 'Area address is required';
    }
    if (values.name === '') {
        errors.name = 'Name is required';
    }
    if (values.forgot_otp === '') {
        errors.forgot_otp = 'OTP is required';
    }
    if (values.change_new_password === '') {
        errors.change_new_password = 'New Password is required';
    }
    if (values.change_old_password === '') {
        errors.change_old_password = 'Old Password is required';
    }
    if (values.billingname === '') {
        errors.billingname = 'Name is required';
    }
    if (values.subject === '') {
        errors.subject = 'Subject is required';
    } 
    if (values.phone === '' || values.phone === null) {
        errors.phone = 'Phone is required';
    }

    if (values.billingphone === '') {
        errors.billingphone = 'Phone is required';
    }
    if (values.mobile === '') {
        errors.mobile = 'Phone is required';
    }

    if (values.password === '') {
        errors.password = 'Password is required';
    }
    if (values.gst === '') {
        errors.gst = 'GSTIN is required';
    }
    if (values.cin === '') {
        errors.cin = 'CIN is required';
    }
    if (values.address === '') {
        errors.address = 'Address is required';
    }
    if (values.pincode === '' || values.pincode === null) {
        errors.pincode = 'Pin Code is required';
    }
    if (values.billingaddress === '') {
        errors.billingaddress = 'Address is required';
    }
    if (values.billingpincode === '') {
        errors.billingpincode = 'Pin Code is required';
    }
    // if (values.city === '') {
    //     errors.city = 'City is required';
    // }
    if (values.state === '' || values.state === null) {
        errors.state = 'State is required';
    }
    if (values.country === '' || values.country === null) {
        errors.country = 'Country is required';
    }
    return errors;
};