import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AppRedirect = () => {
  const [isRedirecting, setIsRedirecting] = useState(true);
  const [isDesktop, setIsDesktop] = useState(false);

  // Fetching data from Redux store
  const navdata = useSelector(state => state.masterdata.navdata);
  const footerdata = useSelector(state => state.masterdata.footerdata);

  // Filter footerdata for blog posts
  const data = footerdata.filter(ele => ele.posts_type === "blog");

  // Filter mobile app links from navdata
  const mobileapplink = navdata.filter(ele => ele.list_key === "mobile_app");
  const androidLink = mobileapplink.find(ele => ele.name === "Android");
  const iosLink = mobileapplink.find(ele => ele.name === "IOS");

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    // Check for Android device
    if (/android/i.test(userAgent) && androidLink && androidLink.url) {
      // Redirect to the Google Play Store for Android
      window.location.href = androidLink.url;
    } 
    // Check for iOS device
    else if (/iphone|ipod|ipad/i.test(userAgent) && iosLink && iosLink.url) {
      // Redirect to the Apple App Store for iOS
      window.location.href = iosLink.url;
    } 
    // For desktop (laptop), check if it's not a mobile device
    else {
      setIsDesktop(true);
      setIsRedirecting(false);
    }
  }, [androidLink, iosLink]);

  return (
    <div>
      {isRedirecting ? (
        <div>Redirecting...</div>
      ) : isDesktop ? (
        <div>
          {data.length > 0 ? (
            <div dangerouslySetInnerHTML={{ __html: data[0].body }} />
          ) : (
            <div className="custom-height"></div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AppRedirect;
